import ApiService from "./ApiService";

export const getModelosChecklist = async () => {
    const { data } = await ApiService.get(`modelos-checklist`);
    return data;
};

export const getModeloChecklistByID = async (idChecklist) => {
    const { data } = await ApiService.get(`modelos-checklist/${idChecklist}`);
    return data;
};

export const getModeloStatusChecklistByID = async (idChecklist) => {
    const { data } = await ApiService.get(`checklist-status/${idChecklist}`);
    return data;
};

export const getTipoApp = async () => {
    const { data } = await ApiService.get(`tipo-app`);
    return data;
};

export default { getModelosChecklist, getModeloChecklistByID, getModeloStatusChecklistByID, getTipoApp };
