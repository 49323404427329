
import moment from 'moment';
import Datatable from "@/components/datatable/Datatable.vue";
import { defineComponent, reactive, ref, onMounted, toRefs, Ref,computed ,watch} from "vue";
import { useRouter } from "vue-router";
import { ContentLoader } from 'vue-content-loader';
import ModalFormModeloChecklist from './components/modelo-checklist/ModalFormModeloChecklist.vue';
import ModalFormModeloStatusChecklist from './components/status-checklist/ModalFormModeloStatusChecklist.vue';
import ModalCopiarBaseModeloStatusChecklist from './components/status-checklist/ModalCopiarBaseModeloStatusChecklist.vue';
import ModalCopiarPerguntasModeloChecklist from './components/modelo-checklist/ModalCopiarPerguntasModeloChecklist.vue';
import ModalCopiarBaseModeloChecklist from './components/base-checklist/ModalCopiarBaseModeloChecklist.vue';
import ModalContinueChecklist from './components/ModalContinueChecklist.vue'
import Swal from "sweetalert2/dist/sweetalert2.js";
import useAlert from "@/composables/Alert";
import {Modal} from "bootstrap";
import useEmitter from "@/composables/Emmiter";
import { getModeloChecklistByID, getModelosChecklist } from '@/services/ModeloChecklistService';

interface IOption {
  label: string;
  value: string;
}

interface IOptions {
  setorCor: Array<IOption>;
    modelosChecklist: Array<IOption>;
}

interface checklist {  
    checklist: number,
    nome: string,
    tabela: string,
    diretorio:string,
    menus: string,
    impressao: string,
    api: string,
    arteorcamento: string,
    nomereal: string,
    completo: number,
    formulario: string,
    temoficinadigital: number,
    descricao: string

}

interface IModeloChecklist {
    id?: number;
    name: string;
    acceptNull: boolean;
    primaryKey: boolean;
    autoIncrement: boolean;
    type: string;
    showInChecklistPerguntas: boolean;
    checklistPergunta: {
      status: number;
      pergunta: string;
      tipo: string
      opcoes: string;
      valor: string;
      obrigatorio: number;
      default: string;
      query: string;
      ordem: number;
      tamanho: string;
      selecione: number;
      class: string;
      grupo: string;
      ordemGrupo: number;
      titulo: string;
      tamanhoGrupo: string;
      boasvindas: number;
      editavel: number;
      foto: number;
      video: number;
      audio: number;
      extra: string;
      utilizacao: string;
      orcamentoDigital: number;
      oportunidades: number;
      tamanhoCampo: number;
      perguntaObrigatorio: string;
      valorObrigatorio: string;
    }
}

export default defineComponent({
  name: "cadastros-concessionaria-list",
  components: {
    Datatable,
    ContentLoader,
    ModalFormModeloChecklist,
    ModalCopiarPerguntasModeloChecklist,
    ModalCopiarBaseModeloChecklist,
    ModalFormModeloStatusChecklist,
    ModalCopiarBaseModeloStatusChecklist,
    ModalContinueChecklist
  },

  setup() {
    const { showTimeAlert } = useAlert();
    const router = useRouter();
    const emitter = useEmitter();
    const nameTabelaChecklist = ref()
    const formChecklist = reactive({
      checklist: null,
      nome: "",
      tabela: "",
      diretorio: "",
      menus: "dashboard;finalizados;sair",
      impressao: "",
      api: "checklist/acampa/v1",
      nomereal: "",
      arteorcamento: "",
      formulario: "",
      temoficinadigital: 0,
      completo: 0,
      descricao: "",
    });    
   const allChecklists : Ref<Array<checklist>>= ref([]);

    const arraySave : any = reactive({
      base: [],
      status: [],
      modelo: []
    })
    
    const rules = ref({
      nome: [
        {
          required: true,
          message: "Preencha o nome do checklist",
          trigger: "blur",
        },
      ],
      impressao: [
        {
          required: true,
          validator: (rule: any, value: any, callback: any) => {
            const  idChecklistImpressao = value.match(/\d+/g)
            if(idChecklistImpressao == null) return true
            
            if(allChecklists.value.find(checklist => checklist.checklist == idChecklistImpressao)) return false

             return true
          },
          message: "ID do checklist já existe",
          trigger: "blur",
        },
      ],
      tabela: [
        {
          validator:  (rule: any, value: string, callback: any) => {
            
            if(!value) {
              callback(new Error('Preencha o nome da tabela do checklist'));  
              return false
            }else if(allChecklists.value.find((checklist) => checklist.tabela.replace('checklist_','').toLocaleUpperCase() == value.toLocaleUpperCase() )){
              callback(new Error('Nome da tabela ja existe'));
              return false
            }   
             return true
          },
          trigger: "blur",
        },
      ],
      checklist: [
        {
          required: true,
          message: "Selecione o checklist",
          trigger: "blur",
        },
      ],
      diretorio: [
        {
          validator:  (rule: any, value: string, callback: any) => {            
            if(!value) {
              callback(new Error('Preencha o diretório do checklist'));  
              return false
            }else if(allChecklists.value.find((checklist) => checklist.diretorio.replace('checklist_','').toLocaleUpperCase() == value.toLocaleUpperCase() )){
              callback(new Error('Diretório já esta em uso'));
              return false
            }   
             return true
          },         
          trigger: "blur",
        },
      ],
    });

    const modalContinueChecklist = ref()
    const updateArrayCheklistsStorage = ref(true)
    const idSelectSavedModal = ref()
    const resetModal = ref(false);
    const sqlResultBase = ref("");
    const sqlResultTabelaBase = ref("");
    const sqlResultStatus = ref("");
    const sqlResultModelo = ref("");
    const sqlResultPerguntas = ref("");
    const dadosModeloChecklist = ref();
    const dadosStatusChecklist = ref();
    const refStatusEdited = ref();
    const refCampoEdited = ref();
    const collapseControl = ref({
      show1: false,
      show2: false,
      show3: false,
    });
    const formBaseChecklistRef = ref<null | HTMLFormElement>(null); 
    const formSelectChecklist = ref<null | HTMLFormElement>(null); 

    const rulesStatusChecklist = ref({});

    const formPerguntasChecklist: Ref<Array<IModeloChecklist>>= ref([]);

    const formStatusChecklist:any = ref([]);
        
    const rulesPerguntasChecklist = ref({});
    const checklistSelectedData = ref({});
    const loading = ref(false);
    const disabledChecklistRefresh = ref(false);

    function saveChecklist(){
      arraySave.base = formChecklist
      arraySave.status = formStatusChecklist.value
      arraySave.modelo = formPerguntasChecklist.value
      let arrayChecklists : any = []
      const getChecklistStorage : any = window.localStorage.getItem('checklists')       
      
      if(idSelectSavedModal.value == undefined && window.localStorage.getItem('checklists')){     
        const todosChecklist = JSON.parse(getChecklistStorage)
        arrayChecklists = todosChecklist
        arrayChecklists.push(arraySave)
        window.localStorage.setItem('checklists', JSON.stringify(arrayChecklists))
        idSelectSavedModal.value = todosChecklist.length - 1

        showTimeAlert('O seu checklist está sendo salvo automaticamente a partir de agora');
        return
      }

      if(!window.localStorage.getItem('checklists')){        
        arrayChecklists.push(arraySave)
        window.localStorage.setItem('checklists',JSON.stringify(arrayChecklists))
        idSelectSavedModal.value = arrayChecklists.length - 1

        showTimeAlert('O seu checklist está sendo salvo automaticamente a partir de agora');
      }     
    }

    watch(() => formChecklist, () => {     
        arraySave.base = formChecklist     
     
        const allChecklists = getChecklistStorage()

        if(idSelectSavedModal.value >= 0){ 
          if(allChecklists[idSelectSavedModal.value]){
            allChecklists[idSelectSavedModal.value].base = arraySave.base         
          }
          window.localStorage.setItem('checklists', JSON.stringify(allChecklists))
          return
       }
    },{ deep: true })

    watch(() => formStatusChecklist.value, () => {     
        arraySave.status = formStatusChecklist.value       
     
        const allChecklists = getChecklistStorage()
        if(idSelectSavedModal.value >= 0){ 
          if(allChecklists[idSelectSavedModal.value]){
            allChecklists[idSelectSavedModal.value].status = arraySave.status 
          }
            window.localStorage.setItem('checklists', JSON.stringify(allChecklists))
            return
        }
         
    },{ deep: true })

    watch(() => formPerguntasChecklist.value, () => {     
        arraySave.modelo = formPerguntasChecklist.value      
     
        const allChecklists = getChecklistStorage()
        if(idSelectSavedModal.value >= 0){ 
          if(allChecklists[idSelectSavedModal.value]){

            allChecklists[idSelectSavedModal.value].modelo = arraySave.modelo         
          }

         window.localStorage.setItem('checklists', JSON.stringify(allChecklists))

          return
        }

         
    },{ deep: true })


    function getChecklistStorage(){
      const getChecklistStorage : any = window.localStorage.getItem('checklists') 
      return  JSON.parse(getChecklistStorage)
    }

    const options: Ref<IOptions> = ref({
      modelosChecklist: [],
      setorCor: [
        {
          label: "Warning",
          value: "warning"
        },
        {
          label: "Info",
          value: "info"
        },
        {
          label: "Primary",
          value: "primary"
        },
        {
          label: "Danger",
          value: "danger"
        },
        {
          label: "Success",
          value: "Success"
        },
        {
          label: "Darkyellow",
          value: "darkyellow"
        },
        {
          label: "End",
          value: "end"
        },
        {
          label: "Green",
          value: "green"
        }
      ],
    });

    onMounted(() => {
      listaModelos();     
      const refModal = document.getElementById("modalContinueChecklist");
      if (refModal) {
        document.getElementById("layout_content")?.appendChild(refModal);       
      }
      modalContinueChecklist.value = new Modal(refModal, {keyboard: false, backdrop: 'static'});
      const allChecklists : any = window.localStorage.getItem('checklists')
      if(allChecklists){
        const arrayAllChelists: any = JSON.parse(allChecklists)
        if(arrayAllChelists.length > 0){
         modalContinueChecklist.value.show()
        }
      }     
    });

    function esvaziarCampos(refEsvaziar) {
      Swal.fire({
        title: "Deseja confirmar a exclusão dos campos?",
        text: "A ação excluirá todos os campos criados e não poderá ser desfeita",
        position: 'center',
        showConfirmButton: true,
        reverseButtons: true,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-flex background_btn_1_opct text-white",
          cancelButton: "btn btn-light me-3",
        },
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        focusConfirm: false,
      }).then((result) => {
        if (result.isConfirmed) {
          if(refEsvaziar == 'status'){
            formStatusChecklist.value = [];
            sqlResultStatus.value = "";        


          } else if(refEsvaziar = 'modelo') {
            formPerguntasChecklist.value = [];
            sqlResultModelo.value = "";        
            sqlResultPerguntas.value = "";  
          }
          showTimeAlert("Os campos foram excluídos com sucesso!");
        }
      });
    }

    async function listaModelos(){
      loading.value = true;
      disabledChecklistRefresh.value = true;

      let response = await getModelosChecklist();
      allChecklists.value = response

      nameTabelaChecklist.value = response.map((modelo) => {
        return {
          tabela: modelo.tabela,
          checklist: modelo.checklist
        }
      })      
      options.value.modelosChecklist = response.map((modelo) => {
        return {
            label: `(${modelo.checklist}) ${modelo.nome}`,
            value: modelo.checklist
        }
      });

      setTimeout(() => {
        disabledChecklistRefresh.value = false;
      }, 5000);

      loading.value = false;   
      loading.value = false;   
    }

    function ordenaStatusChecklist(){
      formStatusChecklist.value = formStatusChecklist.value.sort((a, b) => {
        if (a.status < b.status) {
          return -1
        } else if (a.status > b.status) {
          return 1
        }

        return 0;
      });
    }

    async function listaModeloByID(id){
      loading.value = true;

      let response = await getModeloChecklistByID(id);

      checklistSelectedData.value = response;
      loading.value = false;   
    }

    function goTo(url){
      router.push(url)
    };
    
    function onDropStatus(evt, idDrop) {
      const idDrag = evt.dataTransfer.getData('idItem');
      const itemDrag = formStatusChecklist.value.find(item => item.id == idDrag);
      const itemDrop = formStatusChecklist.value.find(item => item.id == idDrop);

      const statusDrag = itemDrag?.status;
      const statusDrop = itemDrop?.status;

      if(itemDrag){
        itemDrag.status = statusDrop as number;
      }
      
      if(itemDrop){
        itemDrop.status = statusDrag as number;
      }

      ordenaStatusChecklist();
    };

    function startDrag(evt, id) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('idItem', id)
    };
    
    function onDrop(evt, idDrop) {
      const idDrag = evt.dataTransfer.getData('idItem');
      const indexDrag = formPerguntasChecklist.value.findIndex((_,index) => index == idDrag);
      const indexDrop = formPerguntasChecklist.value.findIndex((_,index) => index == idDrop);
      const itemDrag = formPerguntasChecklist.value.splice(indexDrag, 1)[0];
      
      formPerguntasChecklist.value.splice(indexDrop, 0, itemDrag);
    };

    function deletarCampo(index){
      formPerguntasChecklist.value.splice(index, 1);
    };

    function deletarCampoStatus(index){
      formStatusChecklist.value.splice(index, 1);
    };

    function copiarModeloBase(dadosChecklist){
      const { 
        nome,
        tabela,
        diretorio,
        menus,
        impressao,
        api,
        arteorcamento,
        nomereal,
        completo,
        formulario,
        temoficinadigital,
        descricao
      } = dadosChecklist;

      formChecklist.nome = nome;
      formChecklist.tabela = tabela.replace("checklist_", "");
      formChecklist.diretorio = diretorio;
      formChecklist.menus = menus;
      formChecklist.impressao = impressao.replace(diretorio, "");
      formChecklist.api = api;
      formChecklist.nomereal = nomereal;
      formChecklist.arteorcamento = arteorcamento;
      formChecklist.formulario = formulario;
      formChecklist.temoficinadigital = temoficinadigital == "1" ? 1 : 0;
      formChecklist.completo = completo == "1" ? 1 : 0;
      formChecklist.descricao = descricao;
    }

    function copiarStatusChecklist(dadosStatusChecklist){
      formStatusChecklist.value = [...formStatusChecklist.value, ...dadosStatusChecklist];
    }

    function copiarModeloPerguntas(dadosPerguntasChecklist){
      formPerguntasChecklist.value = [...formPerguntasChecklist.value, ...dadosPerguntasChecklist];
    }

    function submitBase(){
      if (!formBaseChecklistRef.value) {
        return;
      }

      formBaseChecklistRef.value.validate((valid) => {
        if (valid) {
          gerarBaseChecklistSql();
          saveChecklist()
        }
      });
    }

    function gerarBaseChecklistSql(){
      let { 
        nome,
        tabela,
        diretorio,
        menus,
        impressao,
        api,
        arteorcamento,
        nomereal,
        completo,
        formulario,
        temoficinadigital,
        descricao,
      } = formChecklist;

      tabela = `checklist_${tabela}`;
      impressao = `${diretorio}${impressao}`;

      const sql = `INSERT INTO checklist (nome, tabela, diretorio, menus, impressao, api, arteorcamento, nomereal, completo, formulario, temoficinadigital, descricao) VALUES ("${nome}", "${tabela}", "${diretorio}", "${menus ?? ''}", "${impressao ?? ''}", "${api ?? ''}", "${arteorcamento ?? ''}", "${nomereal ?? ''}", "${completo}", "${formulario ?? ''}", "${temoficinadigital}", "${descricao ?? ''}");`;

      const sqlTabela = `CREATE TABLE ${tabela} (codCheckList INT NOT NULL AUTO_INCREMENT, codConcessionaria INT NOT NULL, checklist INT NULL, PRIMARY KEY (codCheckList));`

      sqlResultTabelaBase.value = sqlTabela;

      sqlResultBase.value = sql;
    }

    function gerarBaseStatusChecklistSql(){
      if (!formSelectChecklist.value) {
        return;
      }

      formSelectChecklist.value.validate((valid) => {
      
        if (valid) {
          let sql = ``;
          formStatusChecklist.value.forEach(campoStatus => {
            console.log('campoStatus',campoStatus)
            const auxSql = `INSERT INTO checklist_status (checklist, status, setor, setorCor, link, linksalvar, linkproximo, nivel, botao, acao, acaoApi, exibePdf, enviaEmail, header, perguntaAplicavel, oficinaDigital, inicioMecanico, inicioOutros, acesso, tituloPagina, informacaoAdicional) VALUES ("${formChecklist.checklist ?? ''}", "${campoStatus.status ?? ''}", "${campoStatus.setor ?? ''}", "${campoStatus.setorCor ?? ''}", "${campoStatus.link ?? ''}", "${campoStatus.linksalvar ?? ''}", "${campoStatus.linkproximo ?? ''}", "${campoStatus.nivel ?? ''}", "${campoStatus.botao ?? ''}", "${campoStatus.acao ?? ''}", "${campoStatus.acaoApi ?? ''}", "${campoStatus.exibePdf ?? ''}", "${campoStatus.enviaEmail ?? ''}", "${campoStatus.header ?? ''}", "${campoStatus.perguntaAplicavel ?? ''}", "${campoStatus.oficinaDigital ?? ''}", "${campoStatus.inicioMecanico ?? ''}", "${campoStatus.inicioOutros ?? ''}", "${campoStatus.acesso ?? ''}", "${campoStatus.tituloPagina ?? ''}", "${campoStatus.informacaoAdicional ?? ''}");\n`;

            sql += auxSql;
          });

          sqlResultStatus.value = sql;        
        } else {
          document.getElementById(`select-checklist`)!.scrollIntoView({block: "center", inline: "nearest"});

          showTimeAlert("Necessário selecionar um checklist", "error");
        }
      });
    }

    function gerarModeloChecklistSql(){
      if (!formSelectChecklist.value) {
        return;
      }

      formSelectChecklist.value.validate((valid) => {
        if (valid) {
          let auxTableChecklist = options.value.modelosChecklist.find(modelo => modelo.value == formChecklist.checklist)?.label ?? "";
          const nomeTabela =  nameTabelaChecklist.value.find(modelo => modelo.checklist == formChecklist.checklist).tabela
          console.log('nameTabelaChecklist.value', nameTabelaChecklist.value)
          auxTableChecklist = auxTableChecklist.split(") ")[1];

          let sqlPerguntas = ``;
          let sqlModelo = "ALTER TABLE " + "`" + nomeTabela + "`\n";

          formPerguntasChecklist.value.forEach((campoPergunta, ) => {
            let auxSqlModelo = "";
            auxSqlModelo = `ADD COLUMN ${campoPergunta.name} ${campoPergunta.type} ${campoPergunta.acceptNull ? 'NULL' : 'NOT NULL'}${campoPergunta.autoIncrement ? ' AUTO_INCREMENT' : ''},\n`;
           
            sqlModelo += auxSqlModelo;
          });

          const arrayPrimaryKey = formPerguntasChecklist.value.filter(campoPergunta => campoPergunta.primaryKey).map(campoPergunta => campoPergunta.name);

          if(arrayPrimaryKey.length > 0){
            const auxSqlModelo = `ADD PRIMARY KEY ${arrayPrimaryKey.join(',')}`;

            sqlModelo += auxSqlModelo;
          } else {
            sqlModelo = sqlModelo.substring(0, sqlModelo.length - 2) + ";";
          }

          formPerguntasChecklist.value.filter(campoPergunta => campoPergunta.showInChecklistPerguntas).forEach(campoPergunta => {
           console.log('campoPergunta.checklistPergunta.extra',campoPergunta.checklistPergunta.extra)
           
            if(campoPergunta.checklistPergunta.extra === null || campoPergunta.checklistPergunta.extra === ''){
              const auxSqlPerguntas = "INSERT INTO checklist_perguntas (`checklist`, `status`, `campo`, `pergunta`, `tipo`, `opcoes`, `valor`, `obrigatorio`, `default`, `query`, `ordem`, `tamanho`, `selecione`, `class`, `grupo`, `ordemGrupo`, `titulo`, `tamanhoGrupo`, `boasvindas`, `editavel`, `foto`, `video`, `audio`, `utilizacao`, `orcamentoDigital`, `oportunidades`, `tamanhoCampo`, `perguntaObrigatorio`, `valorObrigatorio`)";
            
              const values = ` VALUES ("${formChecklist.checklist ?? ''}", "${campoPergunta.checklistPergunta?.status ?? ''}", "${campoPergunta.name ?? ''}", "${campoPergunta.checklistPergunta?.pergunta ?? ''}", "${campoPergunta.checklistPergunta?.tipo ?? ''}", "${ campoPergunta.checklistPergunta?.opcoes ?? ''}", "${campoPergunta.checklistPergunta?.valor ?? ''}", "${campoPergunta.checklistPergunta?.obrigatorio ?? ''}", "${campoPergunta.checklistPergunta?.default ?? ''}", "${campoPergunta.checklistPergunta?.query ?? ''}", "${campoPergunta.checklistPergunta?.ordem ?? ''}", "${campoPergunta.checklistPergunta?.tamanho ?? ''}", "${campoPergunta.checklistPergunta?.selecione ?? ''}", "${campoPergunta.checklistPergunta?.class ?? ''}", "${campoPergunta.checklistPergunta?.grupo ?? ''}", "${campoPergunta.checklistPergunta?.ordemGrupo ?? ''}", "${campoPergunta.checklistPergunta?.titulo ?? ''}", "${campoPergunta.checklistPergunta?.tamanhoGrupo ?? ''}", "${campoPergunta.checklistPergunta?.boasvindas ?? ''}", "${campoPergunta.checklistPergunta?.editavel ?? ''}", "${campoPergunta.checklistPergunta?.foto ?? ''}", "${campoPergunta.checklistPergunta?.video ?? ''}", "${campoPergunta.checklistPergunta?.audio ?? ''}","${campoPergunta.checklistPergunta?.utilizacao ?? ''}", "${campoPergunta.checklistPergunta?.orcamentoDigital ?? ''}", "${campoPergunta.checklistPergunta?.oportunidades ?? ''}", "${campoPergunta.checklistPergunta?.tamanhoCampo ?? ''}", "${campoPergunta.checklistPergunta?.perguntaObrigatorio ?? ''}", "${campoPergunta.checklistPergunta?.valorObrigatorio ?? ''}");\n`;

              sqlPerguntas += `${auxSqlPerguntas} ${values}`;
            
            }else{
              const auxSqlPerguntas = "INSERT INTO checklist_perguntas (`checklist`, `status`, `campo`, `pergunta`, `tipo`, `opcoes`, `valor`, `obrigatorio`, `default`, `query`, `ordem`, `tamanho`, `selecione`, `class`, `grupo`, `ordemGrupo`, `titulo`, `tamanhoGrupo`, `boasvindas`, `editavel`, `foto`, `video`, `audio`, `extra`, `utilizacao`, `orcamentoDigital`, `oportunidades`, `tamanhoCampo`, `perguntaObrigatorio`, `valorObrigatorio`)";
            
              const values = ` VALUES ("${formChecklist.checklist ?? ''}", "${campoPergunta.checklistPergunta?.status ?? ''}", "${campoPergunta.name ?? ''}", "${campoPergunta.checklistPergunta?.pergunta ?? ''}", "${campoPergunta.checklistPergunta?.tipo ?? ''}", "${ campoPergunta.checklistPergunta?.opcoes ?? ''}", "${campoPergunta.checklistPergunta?.valor ?? ''}", "${campoPergunta.checklistPergunta?.obrigatorio ?? ''}", "${campoPergunta.checklistPergunta?.default ?? ''}", "${campoPergunta.checklistPergunta?.query ?? ''}", "${campoPergunta.checklistPergunta?.ordem ?? ''}", "${campoPergunta.checklistPergunta?.tamanho ?? ''}", "${campoPergunta.checklistPergunta?.selecione ?? ''}", "${campoPergunta.checklistPergunta?.class ?? ''}", "${campoPergunta.checklistPergunta?.grupo ?? ''}", "${campoPergunta.checklistPergunta?.ordemGrupo ?? ''}", "${campoPergunta.checklistPergunta?.titulo ?? ''}", "${campoPergunta.checklistPergunta?.tamanhoGrupo ?? ''}", "${campoPergunta.checklistPergunta?.boasvindas ?? ''}", "${campoPergunta.checklistPergunta?.editavel ?? ''}", "${campoPergunta.checklistPergunta?.foto ?? ''}", "${campoPergunta.checklistPergunta?.video ?? ''}", "${campoPergunta.checklistPergunta?.audio ?? ''}", '${campoPergunta.checklistPergunta?.extra ?? ""}', "${campoPergunta.checklistPergunta?.utilizacao ?? ''}", "${campoPergunta.checklistPergunta?.orcamentoDigital ?? ''}", "${campoPergunta.checklistPergunta?.oportunidades ?? ''}", "${campoPergunta.checklistPergunta?.tamanhoCampo ?? ''}", "${campoPergunta.checklistPergunta?.perguntaObrigatorio ?? ''}", "${campoPergunta.checklistPergunta?.valorObrigatorio ?? ''}");\n`;

              sqlPerguntas += `${auxSqlPerguntas} ${values}`;
            }           
          });

          sqlResultModelo.value = sqlModelo;        
          sqlResultPerguntas.value = sqlPerguntas;        
        } else {
          document.getElementById(`select-checklist`)!.scrollIntoView({block: "center", inline: "nearest"});

          showTimeAlert("Necessário selecionar um checklist", "error");
        }
      });
    }

    function setCopy(text) {
      showTimeAlert('Código copiado com sucesso!');

      navigator.clipboard.writeText(text);
    }

    function adicionarStatus(newStatus){
      formStatusChecklist.value.push(newStatus);
    }

    function editarStatus(editedStatus){
      formStatusChecklist.value[refStatusEdited.value] = editedStatus;
    }
    
    function adicionarCampo(newCampo){
      formPerguntasChecklist.value.push(newCampo);
    }

    function editarCampo(editedCampo){
     formPerguntasChecklist.value[refCampoEdited.value] = JSON.parse(JSON.stringify(editedCampo));     
    }

    function propsModalFormModeloChecklist(campo,index){
      formStatusChecklist.value = [...formStatusChecklist.value]
      dadosModeloChecklist.value = JSON.parse(JSON. stringify(campo)); 
      refCampoEdited.value = index
    }

    function selectedChecklist(checklist,index){
      idSelectSavedModal.value = index

      Object.assign(formChecklist,checklist.base)      
      formStatusChecklist.value = checklist.status
      formPerguntasChecklist.value = checklist.modelo
    }

    function addNewChecklist(newChecklist){
     if(newChecklist){

      idSelectSavedModal.value = null
      formStatusChecklist.value = []
      formPerguntasChecklist.value = []
      formChecklist.api =  "checklist/acampa/v1"
      formChecklist.arteorcamento = ""
      formChecklist.checklist = null
      formChecklist.completo = 0
      formChecklist.descricao = ""
      formChecklist.diretorio = "" 
      formChecklist.formulario = ""
      formChecklist.impressao = ""
      formChecklist.menus = "dashboard;finalizados;sair"
      formChecklist.nome = ""
      formChecklist.nomereal = ""
      formChecklist.tabela = ""
      formChecklist.temoficinadigital = 0   
     }
    }

    const updateChecklistModal = () => {
      modalContinueChecklist.value.show()
      emitter.emit("updateChecklistModal");
    }

    return {
      goTo,
      formChecklist,
      rules,
      formPerguntasChecklist,
      rulesPerguntasChecklist,
      formStatusChecklist,
      rulesStatusChecklist,
      dadosModeloChecklist,
      collapseControl,
      resetModal,
      sqlResultBase,
      sqlResultTabelaBase,
      sqlResultStatus,
      sqlResultModelo,
      sqlResultPerguntas,
      formBaseChecklistRef,
      dadosStatusChecklist,
      loading,
      options,
      checklistSelectedData,
      disabledChecklistRefresh,
      refStatusEdited,
      formSelectChecklist,
      refCampoEdited,
      onDropStatus,
      startDrag,
      onDrop,
      deletarCampo,
      deletarCampoStatus,
      copiarModeloBase,
      copiarStatusChecklist,
      gerarBaseStatusChecklistSql,
      gerarModeloChecklistSql,
      submitBase,
      setCopy,
      listaModeloByID,
      listaModelos,
      esvaziarCampos,
      editarStatus,
      adicionarStatus,
      adicionarCampo,
      editarCampo,
      copiarModeloPerguntas,
      propsModalFormModeloChecklist,
      selectedChecklist,
      modalContinueChecklist,
      updateArrayCheklistsStorage,
      addNewChecklist,
      updateChecklistModal
    };
  },
});
