
  import {onMounted, ref, watch,computed} from "vue";
  import {ContentLoader} from 'vue-content-loader';
  import useAlert from "@/composables/Alert";
  import { hideModal } from "@/core/helpers/dom";

  export default {
    name: "visualizarChecklistModal",
  
    props: {
      dadosModeloChecklist: {
        type: Object,
        required: true,
      },
      id: {
        type: String,
        required: true,
      },
      statusOptions: {
        type: Array,
        required: true,
      }
    },
  
    components: {
      ContentLoader,
    },

    emits: ["add", "update"],

    setup(props, { emit }) {
      let animate = ref(false);
      let jsonText = ref("{}");
      const jsonEditor:any = ref(null);
      const characterCounter  = computed(() : Number=> {
        return formModeloChecklist.value?.checklistPergunta.pergunta?.length
      })
      const showControl = {
        video: []
      }

      const options = ref({
        tipo: [
          {
            label: "Texto",
            options: [
              {
                label: "Text (Geral)",
                value: "text"
              },
              {
                label: "Textarea",
                value: "textarea"
              },
              {
                label: "Número",
                value: "number"
              },
              {
                label: "CPF",
                value: "cpf"
              },
              {
                label: "Telefone",
                value: "tel"
              },
              {
                label: "Email",
                value: "email"
              },
              {
                label: "Placa",
                value: "placa"
              },
              {
                label: "Prisma",
                value: "prisma"
              },
            ]
          },
          {
            label: "Data e Tempo",
            options: [
              {
                label: "Data",
                value: "data"
              },
              {
                label: "Hora",
                value: "hora"
              },
              {
                label: "DataHora",
                value: "datahora"
              },
            ]
          },
          {
            label: "Seleção",
            options: [
              {
                label: "Select Simples",
                value: "select"
              },
              {
                label: "Text-Select",
                value: "textselect"
              },
              {
                label: "Multi-Select",
                value: "multi-select"
              },
              {
                label: "Radio",
                value: "radio"
              },
            ]
          },
          {
            label: "Marcação",
            options: [
              {
                label: "Checkbox",
                value: "checkbox"
              },
              {
                label: "Toggle",
                value: "toggle"
              },
              {
                label: "Combustível",
                value: "combustivel"
              },
            ]
          },
          {
            label: "Assinatura",
            options: [
              {
                label: "Assinatura Storage",
                value: "assinatura_storage"
              },
              {
                label: "Assinatura",
                value: "assinatura"
              },
            ]
          },
          {
            label: "Outros",
            options: [
              {
                label: "Aspectos Externos",
                value: "aspectos_externos"
              },
              {
                label: "Slider Profundidade",
                value: "slider_profundidade"
              },
              {
                label: "Slider Profundidade 2",
                value: "slider_profundidade_2"
              },
              {
                label: "Slider Pressão",
                value: "slider_pressao"
              },
              {
                label: "Senha Responsável",
                value: "senha_responsavel"
              },
              {
                label: "Solicitações",
                value: "solicitacoes"
              },
              {
                label: "Detalhamento",
                value: "detalhamento"
              },
              {
                label: "Detalhamento Diagnóstico",
                value: "detalhamento_diagnostico"
              },
              {
                label: "Detalhamento Serviço",
                value: "detalhamento_servico"
              },
            ]
          },
        ],
        tamanho: [
          {
            label: "col-12",
            value: "col-12", 
          },
          {
            label: "col-11",
            value: "col-11", 
          },
          {
            label: "col-10",
            value: "col-10", 
          },
          {
            label: "col-9",
            value: "col-9", 
          },
          {
            label: "col-8",
            value: "col-8", 
          },
          {
            label: "col-7",
            value: "col-7", 
          },
          {
            label: "col-6",
            value: "col-6", 
          },
          {
            label: "col-5",
            value: "col-5", 
          },
          {
            label: "col-4",
            value: "col-4", 
          },
          {
            label: "col-3",
            value: "col-3", 
          },
          {
            label: "col-2",
            value: "col-2", 
          },
          {
            label: "col-1",
            value: "col-1", 
          },
        ],
        typeColuna: [
          { value: "varchar(10)" },
          { value: "varchar(20)" },
          { value: "varchar(50)" },
          { value: "varchar(100)" },
          { value: "varchar(255)" },
          { value: "text" },
          { value: "int(3)" },
          { value: "int(11)" },
          { value: "int(20)" },
          { value: "timestamp" },
          { value: "blob" },
          { value: "mediumblob" }
        ],
        status: [],
        jsonEditor: {
          mode: 'code',
          language: 'pt-BR'
        }
      });

      const defaultFormModeloChecklist = {
        name: "",
        acceptNull: false,
        primaryKey: false,
        autoIncrement: false,
        type: "",
        showInChecklistPerguntas: true,
        opcoesAux: [],
        checklistPergunta: {
          status: null,
          pergunta: '',
          tipo: null,
          opcoes: "",
          valor: null,
          obrigatorio: null,
          default: null,
          query: null,
          ordem: 0,
          tamanho: "col-12",
          selecione: null,
          class: null,
          grupo: "dados",
          ordemGrupo: 0,
          titulo: "Dados",
          tamanhoGrupo: "col-12",
          boasvindas: 0,
          editavel: 1,
          foto: 0,
          video: 0,
          audio: 0,
          extra: "",
          utilizacao: "1",
          orcamentoDigital: 0,
          oportunidades: 0,
          tamanhoCampo: 250,
          perguntaObrigatorio: null,
          valorObrigatorio: null,
        },
        auxSelecaoTipo: ""
      };

      let formModeloChecklist = ref({ ...JSON.parse(JSON.stringify(defaultFormModeloChecklist)) });
  
      const consultorModal: any = ref({});

      const formModeloChecklistRef = ref<null | HTMLFormElement>(null);

      const { showTimeAlert } = useAlert();

      const rules = ref({
        name: [
          {
            required: true,
            message: "Preencha o nome do campo",
            trigger: "blur",
          }
        ],
        acceptNull: [
          {
            required: true,
            message: "Selecione uma opção",
            trigger: "blur",
          }
        ],
        primaryKey: [
          {
            required: true,
            message: "Selecione uma opção",
            trigger: "blur",
          }
        ],
        autoIncrement: [
          {
            required: true,
            message: "Selecione uma opção",
            trigger: "blur",
          }
        ],
        type: [
          {
            required: true,
            message: "Selecione o tipo da coluna",
            trigger: "blur",
          }
        ],
        showInChecklistPerguntas: [
          {
            required: true,
            message: "Selecione uma opção",
            trigger: "blur",
          }
        ],
        "checklistPergunta.pergunta": [
          {
            validator: (rule: any, value: any, callback: any) => {
              if(characterCounter.value >= 256)  return false
              return true
          },
            required: false,
            message: "Limite máximo de caracteres excedido: 255.",
            trigger: "blur",
          }
        ],
        "checklistPergunta.status": [
          {
            required: true,
            message: "Selecione um status",
            trigger: "blur",
          }
        ],
        "checklistPergunta.tipo": [
          {
            required: true,
            message: "Preencha o tipo do campo",
            trigger: "blur",
          }
        ],
        "checklistPergunta.obrigatorio": [
          {
            required: true,
            message: "Selecione uma opção",
            trigger: "blur",
          }
        ],
      });

      onMounted(() => {
        if(props.statusOptions && props.statusOptions.length > 0){
          options.value.status = geraOptionsStatus(props.statusOptions);
        }
      })

      watch(() => props.dadosModeloChecklist, () => {
        if(props.dadosModeloChecklist){
          let opcoesAux = [];
          let auxSelecaoTipo = "";

          if(props.dadosModeloChecklist.checklistPergunta){
            auxSelecaoTipo = ['select', 'textselect', 'multi-select','radio'].includes(props.dadosModeloChecklist.checklistPergunta.tipo) ? (props.dadosModeloChecklist.checklistPergunta.query ? "variavel" : "opcoes") : "";
            if(auxSelecaoTipo && auxSelecaoTipo == "opcoes"){
              const auxOpcoes = props.dadosModeloChecklist.checklistPergunta.opcoes.replaceAll(" ", "").split(";");
              const auxValues = props.dadosModeloChecklist.checklistPergunta.valor.replaceAll(" ", "").split(";");

              opcoesAux = auxOpcoes.map((opcao, index) => {
                return {
                  label: opcao,
                  value: auxValues[index]
                }
              });
            }
          }

          formModeloChecklist.value = { ...props.dadosModeloChecklist, auxSelecaoTipo, opcoesAux };
        } else {
          clearForm();
        }
      });

      function clearForm() {
        formModeloChecklist.value = {
            name: "",
            acceptNull: false,
            primaryKey: false,
            autoIncrement: false,
            type: "",
            showInChecklistPerguntas: true,
            opcoesAux: [],
            checklistPergunta: {
              status: null,
              pergunta: null,
              tipo: null,
              opcoes: "",
              valor: null,
              obrigatorio: null,
              default: null,
              query: null,
              ordem: 0,
              tamanho: "col-12",
              selecione: null,
              class: null,
              grupo: "dados",
              ordemGrupo: 0,
              titulo: "Dados",
              tamanhoGrupo: "col-12",
              boasvindas: 0,
              editavel: 1,
              foto: 0,
              video: 0,
              audio: 0,
              extra: "",
              utilizacao: "1",
              orcamentoDigital: 0,
              oportunidades: 0,
              tamanhoCampo: 250,
              perguntaObrigatorio: null,
              valorObrigatorio: null,
            },
            auxSelecaoTipo: ""
        }
      }

      watch(() => props.statusOptions, () => {
        if(props.statusOptions && props.statusOptions.length > 0){
          options.value.status = geraOptionsStatus(props.statusOptions);
        } else {
          options.value.status = [];
        }        
      });

      function geraOptionsStatus(statusOptions){
        const auxOptions = props.statusOptions.map((status: any) => {
          return {
            label: `(${status.status}) ${status.setor}`,
            value: status.status
          }
        });

        return auxOptions;
      }
      
      function closeModal() {
        const modal = document.getElementById(`${props.id}_modal`);
        hideModal(modal);

        clearForm();
      };

      function save() {
        if (!formModeloChecklistRef.value) {
          return;
        }

        formModeloChecklistRef.value.validate((valid) => {
          if (valid) {
            const tipoSelecaoOpcoes = ['select', 'textselect', 'multi-select','radio'].includes(formModeloChecklist.value.checklistPergunta.tipo ?? "") && formModeloChecklist.value.auxSelecaoTipo == 'opcoes';

            let auxTextOpcoes = "";
            let auxValueOpcoes = "";

            if(tipoSelecaoOpcoes){
              formModeloChecklist.value.checklistPergunta.query = null;
              formModeloChecklist.value.opcoesAux.forEach((opcao: any, index) => {
                const lastOpcao = formModeloChecklist.value.opcoesAux.length == index + 1;
                auxTextOpcoes +=  lastOpcao ? opcao.label : `${opcao.label};`;
                auxValueOpcoes += lastOpcao ? opcao.value : `${opcao.valuepode };`;
              });

            }

            const form = {
              ...formModeloChecklist.value,
              opcoes: auxTextOpcoes,
              valor: auxValueOpcoes
            };

            if(props.dadosModeloChecklist){
              emit("update", form);
              showTimeAlert("O campo foi editado com sucesso!");
            } else {
              emit("add", form);
              showTimeAlert("O campo foi adicionado com sucesso!");
            }

            closeModal();
          }
        });
      };

      function querySearchType(queryString: string, cb: any) {
        const results = queryString
        ? options.value.typeColuna.filter(type => type.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
        : options.value.typeColuna;

        // call callback function to return suggestions
        cb(results)
      }

      const handleSelect = (item) => {
      }
  
      return {
        animate,
        consultorModal,
        formModeloChecklist,
        formModeloChecklistRef,
        options,
        jsonEditor,
        rules,
        showControl,
        save,
        querySearchType,
        handleSelect,
        characterCounter
      }
    }
  }
