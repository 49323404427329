
import { defineComponent, reactive, ref, onMounted, watch} from "vue";
import { hideModal } from "@/core/helpers/dom";
import useEmitter from "@/composables/Emmiter";

export default defineComponent({
  name: "visualizarChecklistModal",
  props: {     
      checklistUpdate: {
        type: String,
        required: true,
      },
    },
  emits: ["selected","isUpdate","addNewChecklist"],
  setup(props,{emit}){
    const emitter = useEmitter();
    const allChecklists : any = ref()
    
    onMounted(()=>{
      const allChecklistslocalStorage = window.localStorage.getItem('checklists')
      if(allChecklistslocalStorage){
        allChecklists.value =  JSON.parse(allChecklistslocalStorage)
      }      
    })

    emitter.on("updateChecklistModal", () => {
      const Checklists: any = window.localStorage.getItem('checklists')
      const arrayChecklists : any = JSON.parse(Checklists)
      allChecklists.value = arrayChecklists
    })
    
    watch(() => props.checklistUpdate,()=> {
      const Checklists: any = window.localStorage.getItem('checklists')
      const arrayChecklists : any = JSON.parse(Checklists)
      allChecklists.value = arrayChecklists
      emit('isUpdate', false)
    })

    function closeModal() {
        const modal = document.getElementById('modalContinueChecklist');
        hideModal(modal);
      };

    const selectChecklist = (checklist,index) => {
      closeModal()
      emit("selected", checklist,index);
    }

    const deleteChecklist = (index) => {
      allChecklists.value.splice(index,1)
      window.localStorage.setItem('checklists',JSON.stringify(allChecklists.value))
    }

    const addNewChecklist = (addNewChecklist) => {
      emit("addNewChecklist", addNewChecklist);
    }

  return {
    allChecklists,
    selectChecklist,
    closeModal,
    deleteChecklist,
    addNewChecklist
  }
  }
})
