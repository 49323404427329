
import {computed, onMounted, Ref, ref, watch} from "vue";
import {ContentLoader} from 'vue-content-loader';
import { getModeloStatusChecklistByID, getModelosChecklist } from '@/services/ModeloChecklistService';
import { hideModal } from "@/core/helpers/dom";
import { ElTable } from 'element-plus';

interface IOption {
  label: string;
  value: string;
}

interface IOptions {
  modelosChecklist: Array<IOption>
}
    
interface IPergunta {
  campo: string;
  pergunta: string;
  tipo: string;
}

interface IModeloStatusChecklist {
  id: Number,
  checklist: Number,
  status: Number,
  setor: String,
  setorCor: String,
  link: String,
  linksalvar: String,
  linkproximo: String,
  nivel: String,
  botao: String,
  acao: String,
  acaoApi: String,
  exibePdf: Number,
  enviaEmail: Number,
  header: Number,
  perguntaAplicavel: String,
  oficinaDigital: Number,
  inicioMecanico: Number,
  inicioOutros: Number,
  acesso: String,
  tituloPagina: String,
  informacaoAdicional: String
}

export default {
  name: "copiarBaseStatusChecklistModal",
  emits: ['copiarModelo'],
  props: {
    id: {
      type: String,
      required: true,
    },
    resetModal: {
      type: Boolean,
      required: true,
    },
    optionsModeloChecklist: {
      type: Array,
      required: true,
    }
  },

  components: {
    ContentLoader,
  },
  
  setup(props, { emit }) {
    let animate = ref(false);
    
    const multipleTableRef = ref<InstanceType<typeof ElTable>>()
    const modelosStatusChecklist: Ref<Array<IModeloStatusChecklist>> = ref([]);

    const form = ref({
        modeloStatusChecklist: ""
    });

    const multipleSelection = ref([]);
    const search = ref('');

    watch(() => props.resetModal, () => {
      search.value = "";
    });

    const handleSelectionChange = (val) => {
      console.log("handleSelectionChange", val)
      multipleSelection.value = val
    };

    async function listaModeloStatusByID(id){
      animate.value = true;

      let response = await getModeloStatusChecklistByID(id);

      modelosStatusChecklist.value = response;
      animate.value = false;   
    };
    
    function emitirCopiarModelo(){
      emit("copiarModelo", multipleSelection.value);
      search.value = "";
      multipleTableRef.value!.clearSelection();
      closeModal();
    };

    function closeModal(){
      const modal = document.getElementById(`${props.id}_modal`);
      hideModal(modal);
    };

    const filterTableData = computed(() =>
      modelosStatusChecklist.value.filter(
        (data) =>
        !search.value ||
        data.status.toString().toLowerCase().includes(search.value.toLowerCase()) ||
        data.setor.toLowerCase().includes(search.value.toLowerCase())
      )
    );

    return {
      animate,
      form,
      modelosStatusChecklist,
      multipleSelection,
      search,
      filterTableData,
      multipleTableRef,
      listaModeloStatusByID,
      emitirCopiarModelo,
      handleSelectionChange
    }
  }
}
